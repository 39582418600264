<script>
  import { required, maxLength } from "vuelidate/lib/validators";
  import validationMessages from "@/components/validations";
  import General from "@/services/General";


  export default {
    components: { validationMessages },

    data() {
      return {
        username: "",
        password: "",
        showModal: false,
        submitted: false,
        tryingToSubmit: false,
        showLoader: false,
        csrf_token: localStorage.getItem("csrf_token"),
        error: null,
      };
    },

    validations: {
      username: {
        required,
        maxLength: maxLength(100),
      },
      password: {
        required,
        maxLength: maxLength(255),
      },
    },

    methods: {
      closeModal() {
        this.showModal = false;
      },

      async addTenantVPNUser() {
        this.tryingToSubmit = true;
        this.submitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.tryingToSubmit = false;
          return;
        }

        let formData = {
          username: this.username,
          password: this.password
        };
        try {
          const response = await General.addTenantVPNUsers(formData);
          if (response.error) {
            this.failedmsg(response.error);
          } else {
            this.successmsg('Successfully saved new VPN User');
            this.closeModal();
          }
        } catch (error) {
          this.error = error.response?.data?.error || "An error occurred";
          this.failedmsg('Failed to save new VPN User', this.error);
        } finally {
          this.tryingToSubmit = false;
          this.refreshData();
        }
      },

      refreshData() {
        this.$emit("onRefresh");
      },

      resetForm() {
        this.username = "";
        this.password = "";
        this.$v.$reset();
        this.submitted = false;
      }
    },
  };
</script>

<template>
  <b-modal id="add_vpn_user" size="l" v-model="showModal" @hidden="resetForm" title="Add new VPN User" title-class="font-18">
    <form @submit.prevent="addTenantVPNUser" v-if="!showLoader">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">

              <b-form-group label="Username" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="username"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Username'" :validationName="$v.username"></validationMessages>
              </b-form-group>

              <b-form-group label="Password" label-for="formrow-url-input" class="mb-3">
                <b-form-input v-model.trim="password"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Password'" :validationName="$v.password"></validationMessages>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addTenantVPNUser" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
